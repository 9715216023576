import React from 'react';
import './Contact.css';

function Contact() {
  return (



<div class="w-full pt-32 pb-10 px-10" id="contactcontainer">

<h1 class="text-center text-3xl font-bold mb-8 contact-text">Yhteystiedot</h1>

<div class="w-full flex flex-wrap text-center justify-center">

<p>Jaana Siekkinen ja Minna Suomalainen<br></br><br></br>
puh. 050 597 4247<br></br>
arkiapuminja@gmail.com<br></br><br></br>
Ota yhteyttä, niin sovitaan teidän tarpeisiin sopivat palvelut
</p>
   
</div>

<div class="w-full flex flex-wrap text-center justify-center mt-8" id="contactcontainer2">
    <a href="/images/sosiaalipalvelujen-omavalvontasuunnitelma-arkiapuminja.pdf" target="_blank" class="text-black-500 hover:underline">Omavalvontasuunnitelma</a><br></br>

  </div>

</div>


  );
}

export default Contact;